import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { DEFAULT_ERROR_MESSAGE } from "../../constants/validationConstants";
import { fetchBlogPostsByFeederId } from "../actions/actions";
import { BlogPostI } from "../../types/BlogPost.types";
import { BlogPostsByFeederIdResponseI } from "../../types/BlogPostsByFeederIdResponse.types";

interface BlogPostsStateI {
  isLoading: boolean;
  error: string | null;
  blogPosts: BlogPostI[];
  count: number;
  isFetchingPosts: boolean;
  pageSize: number;
}

export const initialState: BlogPostsStateI = {
  count: 0,
  isLoading: false,
  error: null,
  blogPosts: [],
  isFetchingPosts: true,
  pageSize: 2,
};

export const blogPostsSlice = createSlice({
  name: "blogPosts",
  initialState,
  reducers: {
    setBlogPosts: (state, action) => {
      state.blogPosts = state.blogPosts.concat(action.payload);
    },
    setIsFetchingPosts: (state, action) => {
      state.isFetchingPosts = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBlogPostsByFeederId.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        fetchBlogPostsByFeederId.fulfilled,
        (state, action: PayloadAction<BlogPostsByFeederIdResponseI>) => {
          state.isLoading = false;
          state.error = null;
          // state.blogPosts = [...state.blogPosts, ...action.payload.results];
          const newItems = action.payload.results.filter(
            (newItem) =>
              !state.blogPosts.some(
                (existingItem) => existingItem.id === newItem.id
              )
          );
          state.blogPosts = [...state.blogPosts, ...newItems];
          state.count = action.payload.count;
        }
      )
      .addCase(
        fetchBlogPostsByFeederId.rejected,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.error = action.payload
            ? action.payload.message
            : DEFAULT_ERROR_MESSAGE;
        }
      );
  },
});

export const { setBlogPosts, setIsFetchingPosts } = blogPostsSlice.actions;

export default blogPostsSlice.reducer;
