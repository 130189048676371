import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { fetchPages } from "../actions/actions";
import { PageLinkI } from "../../types/PageLink.types";
import { DEFAULT_ERROR_MESSAGE } from "../../constants/validationConstants";

interface PagesStateI {
  pages: PageLinkI[];
  isLoading: boolean;
  error: null | string;
}

export const initialState: PagesStateI = {
  pages: [],
  isLoading: false,
  error: null,
};

export const pagesSlice = createSlice({
  name: "pages",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPages.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        fetchPages.fulfilled,
        (state, action: PayloadAction<PageLinkI[]>) => {
          state.isLoading = false;
          state.error = null;
          state.pages = action.payload;
        }
      )
      .addCase(fetchPages.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload ? action.payload.message: DEFAULT_ERROR_MESSAGE;
      });
  },
});

export default pagesSlice.reducer;
