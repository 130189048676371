import { useEffect } from 'react';

const useScrollToTop = () => {
  
  useEffect(() => {
    const scrollListener = () => {
      window.scrollTo(0, 0);
    };

    const urlChangeHandler = () => {
      window.addEventListener('popstate', scrollListener);
      return () => {
        window.removeEventListener('popstate', scrollListener);
      };
    };

    urlChangeHandler();

    return () => {
      window.removeEventListener('popstate', scrollListener);
    };
  }, []);
};

export default useScrollToTop;