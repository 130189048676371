import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { hideError } from "../../store/reducers/errorsSlice";
import Catx1 from "../../assets/images/notification/cat@x1.png";
import Catx2 from "../../assets/images/notification/cat@x2.png";
import Catx3 from "../../assets/images/notification/cat@x3.png";
import "./Notification.css";
import { DEFAULT_ERROR_MESSAGE } from "../../constants/validationConstants";

interface Props {
  duration?: number;
}

export const Notification = ({ duration = 5000 }: Props) => {
  const message = useSelector(
    (state: RootState) => state.errorsReducer.message
  );

  const [progress, setProgress] = useState<number>(100);

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;
    let progressInterval: NodeJS.Timeout | null = null;

    if (message) {
      if (timer) clearTimeout(timer);
      if (progressInterval) clearInterval(progressInterval);

      timer = setTimeout(() => {
        dispatch(hideError());
        setProgress(100);
      }, duration);

      progressInterval = setInterval(() => {
        setProgress((prevProgress) => prevProgress - (100 / duration) * 1000);
      }, 1000);
    }

    return () => {
      if (timer) clearTimeout(timer);
      if (progressInterval) clearInterval(progressInterval);
    };
  }, [dispatch, duration, message]);

  return (
    <div
      className="notification"
      style={{ display: message ? "block" : "none" }}
    >
      <div className="notification__inner">
        <div className="notification__header">
          <div className="notification__sticker">
            <img src={Catx1} srcSet={`${Catx2} 2x, ${Catx3} 3x`} alt="cat" />
          </div>

          <div className="notification__title">{DEFAULT_ERROR_MESSAGE}</div>
        </div>

        <p className="notification__message">
          Кажется, в мире добра случилась системная ошибка.
          <br />
          Мы уже в процессе поиска и исправления!
        </p>
      </div>

      <div className="notification__progress-wrapper">
        <div
          className="notification__progress"
          style={{
            width: `${progress}%`,
          }}
        ></div>
      </div>
    </div>
  );
};
