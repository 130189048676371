import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { sendPaymentComment } from "../actions/actions";
import { DEFAULT_ERROR_MESSAGE } from "../../constants/validationConstants";
import { PaymentHistoryItemI } from "../../types/PaymentHistoryItem.types";

interface PaymentCommentStateI {
  isLoading: boolean;
  token: string | null;
  error: string | null;
  paymenCommentResponse: PaymentHistoryItemI | null;
  validationErrors: string[];
}

export const initialState: PaymentCommentStateI = {
  isLoading: false,
  token: null,
  error: null,
  paymenCommentResponse: null,
  validationErrors: []
};

export const paymentCommentSlice = createSlice({
  name: "paymentComment",
  initialState,
  reducers: {
    setPaymentToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    setValidationErrors: (state, action: PayloadAction<string[] | []>) => {
      state.validationErrors = action.payload;
    },
    clearValidationErrors: (state) => {
      state.validationErrors = [];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendPaymentComment.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        sendPaymentComment.fulfilled,
        (state, action: PayloadAction<PaymentHistoryItemI>) => {
          state.isLoading = false;
          state.error = null;
          state.paymenCommentResponse = action.payload;
        }
      )
      .addCase(
        sendPaymentComment.rejected,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.error = action.payload ? action.payload.message: DEFAULT_ERROR_MESSAGE;
        }
      );
  },
});

export const { setPaymentToken, setValidationErrors, clearValidationErrors } = paymentCommentSlice.actions;

export default paymentCommentSlice.reducer;
