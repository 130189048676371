import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { fetchFeeders } from "../actions/actions";
import { FeederI } from "../../types/Feeder.types";
import { DEFAULT_ERROR_MESSAGE } from "../../constants/validationConstants";

interface FeedersStateI {
  feeders: FeederI[];
  isLoading: boolean;
  error: null | string;
}

export const initialState: FeedersStateI = {
  feeders: [],
  isLoading: false,
  error: null,
};

export const feedersSlice = createSlice({
  name: "feeders",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFeeders.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchFeeders.fulfilled, (state, action: PayloadAction<FeederI[]>) => {
        state.isLoading = false;
        state.error = null;
        state.feeders = action.payload;
      })
      .addCase(fetchFeeders.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload ? action.payload.message: DEFAULT_ERROR_MESSAGE;
      });
  },
});

export default feedersSlice.reducer;