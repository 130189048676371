import { useDispatch, useSelector } from "react-redux";

import { AppDispatch, RootState } from "../../store/store";
import { useEffect, useState } from "react";

import { clearValidationErrors } from "../../store/reducers/paymentCommentSlice";

import "./FormNotification.css";

export const FormNotification = ({ duration = 10000 }) => {
  const messages = useSelector(
    (state: RootState) => state.paymentCommentReducer.validationErrors
  );

  const [progress, setProgress] = useState<number>(100);

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;
    let progressInterval: NodeJS.Timeout | null = null;

    if (messages.length) {
      if (timer) clearTimeout(timer);
      if (progressInterval) clearInterval(progressInterval);

      timer = setTimeout(() => {
        dispatch(clearValidationErrors());
        setProgress(100);
      }, duration);

      progressInterval = setInterval(() => {
        setProgress((prevProgress) => prevProgress - (100 / duration) * 1000);
      }, 1000);
    }

    return () => {
      if (timer) clearTimeout(timer);
      if (progressInterval) clearInterval(progressInterval);
    };
  }, [dispatch, duration, messages]);

  return (
    <div
      className="form-notification"
      style={{ display: messages.length ? "block" : "none" }}
    >
      <div className="form-notification__inner">
        {messages.length &&
          messages.map((message: string, index: number) => (
            <p className="form-notification__message" key={index}>
              {message}
            </p>
          ))}
      </div>

      <div className="form-notification__progress-wrapper">
        <div
          className="form-notification__progress"
          style={{
            width: `${progress}%`,
          }}
        ></div>
      </div>
    </div>
  );
};
