import {
  COMMENT_AUTHOR_MAX_LENGTH,
  DEFAULT_ERROR_MESSAGE,
  ELLIPSIS,
} from "../constants/validationConstants";
import { RejectValue } from "../types/RejectValue.types";
import { Timestamp } from "../types/Timestamp.types";

interface FormattedDateTime {
  formattedDate: string;
  formattedTime: string;
}

export const formatFoodPaidDaysToText = (days: number): string => {
  const getDayText = (days: number): string => {
    if (days === 1) {
      return "день";
    } else if (days >= 2 && days <= 4) {
      return "дня";
    } else {
      return "дней";
    }
  };

  const getWeekText = (weeks: number): string => {
    return weeks === 1 ? "неделю" : "недель";
  };

  if (days < 0) {
    return "";
  } else if (days === 0) {
    // return "сегодня";
    return "0 дней";
  } else if (days === 1) {
    return "1 день";
  } else if (days < 7) {
    return `${days} ${getDayText(days)}`;
  } else if (days === 7) {
    return "неделю";
  } else {
    const weeks = Math.floor(days / 7);
    const remainingDays = days % 7;

    if (weeks >= 13 || remainingDays === 0) {
      return `${weeks} ${getWeekText(weeks)}`;
    }

    return `${weeks} ${getWeekText(weeks)} и ${remainingDays} ${getDayText(
      remainingDays
    )}`;
  }
};

export const formatDateAndTime = (timestamp: Timestamp): FormattedDateTime => {
  const date = new Date(timestamp);

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");

  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");

  const formattedDate = `${day}.${month}.${year}`;
  const formattedTime = `${hours}:${minutes}`;

  return { formattedDate, formattedTime };
};

export const truncateUserName = (name: string): string => {
  if (name.length > COMMENT_AUTHOR_MAX_LENGTH) {
    return (
      name.substring(0, COMMENT_AUTHOR_MAX_LENGTH - ELLIPSIS.length) + ELLIPSIS
    );
  }

  return name;
};


export const rejectWithValueHelper = (error: any): RejectValue => {
  let errorMessage = DEFAULT_ERROR_MESSAGE;
  
  if (error.response && error.response.data && error.response.data.error && error.response.data.error.message) {
    errorMessage = error.response.data.error.message;
  } else if (error.message) {
    errorMessage = error.message;
  }

  return {
    message: errorMessage
  };
};