import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { generatePaymentLink } from "../actions/actions";
import { DEFAULT_ERROR_MESSAGE } from "../../constants/validationConstants";

interface PaymentStateI {
  isLoading: boolean;
  error: string | null;
  paymentLink: string;
}

export const initialState: PaymentStateI = {
  isLoading: false,
  error: null,
  paymentLink: ''
};

export const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(generatePaymentLink.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        generatePaymentLink.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.isLoading = false;
          state.error = null;
          state.paymentLink = action.payload;
        }
      )
      .addCase(generatePaymentLink.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload ? action.payload.message: DEFAULT_ERROR_MESSAGE;
      });
  },
});

export default paymentSlice.reducer;