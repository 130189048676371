import React from "react";
import { Outlet } from "react-router-dom";
import { Notification } from "../components/notification/Notification";

import "./Layout.css";
import useScrollToTop from "../hooks/useScrollToTop";
import { FormNotification } from "../components/form-notification/FormNotification";

const Layout = () => {
  
  useScrollToTop();
  
  return (
    <>
    <Notification/>
    <FormNotification/>
      <main className="content">
        <Outlet />
      </main>
      <div className="footer">
        <div className="bottom-banner"></div>
      </div>
    </>
  );
};
export default Layout;
