import { Suspense, lazy, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "./store/store";
import { fetchPages } from "./store/actions/actions";
import { PageLinkI } from "./types/PageLink.types";
import { Spinner } from "./components/spinner/Spinnert";
import Layout from "./layout/Layout";
import HomePage from "./pages/home/HomePage";

const FeedersLazy = lazy(() => import("./pages/feeders/FeedersPage"));
const DonateLazy = lazy(() => import("./pages/donate/DonatePage"));
const FeederDetailLazy = lazy(
  () => import("./pages/feeder-detail/FeederDetailPage")
);
const FeedBackLazy = lazy(() => import("./pages/feedback/FeedBackPage"));
const DefaultLayoutLazy = lazy(
  () => import("./pages/default-layout/DefaultLayout")
);
export const AppRouter = () => {

  const predefinedPaths = [
    "/ulichnye-kormushki",
    "/ulichnye-kormushki/:feederId",
    "/ulichnye-kormushki/:feederId/donate",
    "/ulichnye-kormushki/:feederId/feedback",
  ];

  const dispatch = useDispatch<AppDispatch>();

  const pages: PageLinkI[] = useSelector(
    (state: RootState) => state.pagesReducer.pages
  );
  const isLoadingPages: boolean = useSelector(
    (state: RootState) => state.pagesReducer.isLoading
  );

  useEffect(() => {
    if (pages.length === 0 && !isLoadingPages) {
      dispatch(fetchPages());
    }
  }, [dispatch, pages, isLoadingPages]);

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<HomePage />} />
        <Route
          path="ulichnye-kormushki"
          element={
            <Suspense fallback={<Spinner />}>
              <FeedersLazy />
            </Suspense>
          }
        />

        <Route
          path="ulichnye-kormushki/:feederId"
          element={
            <Suspense fallback={<Spinner />}>
              <FeederDetailLazy />
            </Suspense>
          }
        />
        <Route
          path="ulichnye-kormushki/:feederId/feedback"
          element={
            <Suspense fallback={<Spinner />}>
              <FeedBackLazy />
            </Suspense>
          }
        />
        <Route
          path="ulichnye-kormushki/:feederId/donate"
          element={
            <Suspense fallback={<Spinner />}>
              <DonateLazy />
            </Suspense>
          }
        />

        {pages &&
          pages.map((page: PageLinkI) => {
            if (!predefinedPaths.includes(`/${page.slug}`)) {
              return (
                <Route
                  key={page.id}
                  path={`/${page.slug}`}
                  element={
                    <Suspense fallback={<Spinner />}>
                      <DefaultLayoutLazy page={page} />
                    </Suspense>
                  }
                />
              );
            }

            return null;
          })}
      </Route>
      <Route path="*" element={<Navigate to="/" replace />}></Route>
    </Routes>
  )
}