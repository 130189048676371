import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  blogApi,
  feedersApi,
  notificationApi,
  pagesApi,
  paymentApi,
} from "../../api";

import { PageByIdResponseI } from "../../types/PageByIdResponse.types";
import { PageLinkI } from "../../types/PageLink.types";
import { FeederI } from "../../types/Feeder.types";
import { PaymentData } from "../../types/PaymentData.types";
import { PaymentHistoryResponseI } from "../../types/PaymentHistoryResponse.types";
import { setIsFetchingData } from "../reducers/paymentHistorySlice";
import { AppDispatch } from "../store";
import { setIsFetchingPosts } from "../reducers/blogPostsSlice";
import { RejectValue } from "../../types/RejectValue.types";
import { rejectWithValueHelper } from "../../utils/utils";
import { PaymentHistoryRequestParamsI } from "../../types/PaymentHistoryRequestParams.types";
import { PaymentCommentRequestI } from "../../types/PaymentCommentRequest.types";
import { PaymentHistoryItemI } from "../../types/PaymentHistoryItem.types";
import { FeederBlogRequestParamsI } from "../../types/FeederBlogRequestParams.types";
import { BlogPostsByFeederIdResponseI } from "../../types/BlogPostsByFeederIdResponse.types";
import { NotificationStatusResponseI } from "../../types/NotificationStatusResponse.types";
import { NotificationStatusRequestParamsI } from "../../types/NotificationStatusRequestParams.types";
import { UpdateNotificationStatusResponseI } from "../../types/UpdateNotificationStatusResponse.types";
import { UpdateNotificationStatusRequestI } from "../../types/UpdateNotificationStatusRequest.types";

export const fetchPages = createAsyncThunk<
  PageLinkI[],
  void,
  { rejectValue: RejectValue }
>("pages/fetchPages", async (_, { rejectWithValue }) => {
  try {
    const response = await pagesApi.getPages();

    return response.data;
  } catch (error) {
    return rejectWithValue(rejectWithValueHelper(error));
  }
});

export const fetchPageById = createAsyncThunk<
  PageByIdResponseI,
  number,
  { rejectValue: RejectValue }
>("pages/fetchPageById", async (id, { rejectWithValue }) => {
  try {
    const { data } = await pagesApi.getPageById(id);

    return data;
  } catch (error) {
    return rejectWithValue(rejectWithValueHelper(error));
  }
});

export const fetchFeeders = createAsyncThunk<
  FeederI[],
  void,
  { rejectValue: RejectValue }
>("feeders/fetchFeeders", async (_, { rejectWithValue }) => {
  try {
    const response = await feedersApi.getFeeders();

    return response.data.results;
  } catch (error) {
    return rejectWithValue(rejectWithValueHelper(error));
  }
});

export const fetchFeederById = createAsyncThunk<
  FeederI,
  number,
  { rejectValue: RejectValue }
>("feeders/fetchFeederById", async (id, { rejectWithValue }) => {
  try {
    const { data } = await feedersApi.getFeederById(id);

    return data;
  } catch (error) {
    return rejectWithValue(rejectWithValueHelper(error));
  }
});

export const generatePaymentLink = createAsyncThunk<
  string,
  PaymentData,
  { rejectValue: RejectValue }
>("payment/generatePaymentLink", async (paymentData, { rejectWithValue }) => {
  try {
    const { data } = await paymentApi.generateDonationLink(paymentData);

    return data;
  } catch (error) {
    return rejectWithValue(rejectWithValueHelper(error));
  }
});

export const fetchPaymentHistoryByFeederId = createAsyncThunk<
  PaymentHistoryResponseI,
  PaymentHistoryRequestParamsI,
  { dispatch: AppDispatch; rejectValue: RejectValue }
>(
  "payment/fetchPaymentHistoryByFeederId",
  async (fetchingParams, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await paymentApi.getPaymentHistoryByFeederId(
        fetchingParams
      );

      return data;
    } catch (error) {
      return rejectWithValue(rejectWithValueHelper(error));
    } finally {
      dispatch(setIsFetchingData(false));
    }
  }
);

export const sendPaymentComment = createAsyncThunk<
  PaymentHistoryItemI,
  PaymentCommentRequestI,
  { rejectValue: RejectValue }
>("payment/sendPaymentComment", async (commentData, { rejectWithValue }) => {
  try {
    const response = await paymentApi.createPaymentComment(commentData);

    return response.data;
  } catch (error) {
    return rejectWithValue(rejectWithValueHelper(error));
  }
});

export const fetchBlogPostsByFeederId = createAsyncThunk<
  BlogPostsByFeederIdResponseI,
  FeederBlogRequestParamsI,
  { dispatch: AppDispatch; rejectValue: RejectValue }
>(
  "blog/fetchBlogPostsByFeederId",
  async (fetchingParams, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await blogApi.getBlogPostsByFeederId(fetchingParams);

      return data;
    } catch (error) {
      return rejectWithValue(rejectWithValueHelper(error));
    } finally {
      dispatch(setIsFetchingPosts(false));
    }
  }
);

export const fetchNotificationStatusByFeederId = createAsyncThunk<
  NotificationStatusResponseI,
  NotificationStatusRequestParamsI,
  { rejectValue: RejectValue }
>(
  "notification/fetchNotificationStatusFeederById",
  async (fetchingParams, { rejectWithValue }) => {
    try {
      const { data } = await notificationApi.getNotificationStatus(
        fetchingParams
      );

      return data;
    } catch (error) {
      return rejectWithValue(rejectWithValueHelper(error));
    }
  }
);

export const updateNotification = createAsyncThunk<
  UpdateNotificationStatusResponseI,
  UpdateNotificationStatusRequestI,
  { rejectValue: RejectValue }
>("notification/unpdateNotificationStatus", async (data, { rejectWithValue }) => {
  try {
    const response = await notificationApi.updateNotificationStatus(data);

    return response.data;
  } catch (error) {
    return rejectWithValue(rejectWithValueHelper(error));
  }
});
