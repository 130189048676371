const PAGES_PATH: string = "pages";
const FEEDERS_PATH: string = "feeders";
const PAYMENT_PATH: string = "payment";
const BLOG_PATH: string = "feeder/blog";
const NOTIFICATION_PATH: string = "feeder/notification";

export const HTTP_СONSTANTS = {
  API_PAGES: PAGES_PATH,
  API_PAGES_BY_ID: (id: number) => `${PAGES_PATH}/${id}/`,
  API_FEEDERS: `${FEEDERS_PATH}/`,
  API_FEEDER_BY_ID: (id: number) => `${FEEDERS_PATH}/${id}/`,
  API_FEEDER_BLOG: (feederId: number, pageSize: number, page: number) => `${BLOG_PATH}/list/${feederId}/?page=${page}&page_size=${pageSize}`,
  API_GENERATE_PAYMENT_LINK: (token: string, feederId: number) => `${PAYMENT_PATH}/${token}/${feederId}/`,
  API_PAYMENT_HISTORY: (feederId: number, pageSize: number, page: number) => `${PAYMENT_PATH}/history/${feederId}/?page=${page}&page_size=${pageSize}`,
  API_PAYMENT_COMMENT: (token: string) => `${PAYMENT_PATH}/success/${token}/`,
  API_NOTIFICATION_STATUS: (feederId: number, token: string) => `${NOTIFICATION_PATH}/${token}/${feederId}/`,
  API_NOTIFICATION_UPDATE: `${NOTIFICATION_PATH}/`
};
