import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useTelegram } from "./hooks/useTelegram";
import { useDispatch } from "react-redux";
import { AppDispatch } from "./store/store";
import { LOCAL_STORAGE_KEYS } from "./constants/localStorageConstants";
import { instance } from "./api";
import { useCookies } from "react-cookie";
import { AppRouter } from "./AppRouter";
import { InternalAxiosRequestConfig } from "axios";

function App() {
  const [cookies] = useCookies(["csrftoken"]);
  const { tg } = useTelegram();

  let [searchParams] = useSearchParams();

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const token = searchParams.get("token");

    if (token) {
      localStorage.setItem(LOCAL_STORAGE_KEYS.userToken, token);
    }
  }, [searchParams, dispatch]);

  useEffect(() => {
    if (tg) {
      tg.ready();
    }

    if (tg?.initDataUnsafe?.user?.username) {
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.userName,
        tg.initDataUnsafe.user.username
      );
    }
  }, [tg, dispatch]);

  useEffect(() => {
    const requestInterceptor = instance.interceptors.request.use(
      (config: InternalAxiosRequestConfig) => {
        // console.log("Intercepting request:", config);

        const csrfToken = cookies.csrftoken;

        // console.log("csrfToken", csrfToken);

        if (csrfToken && config.method === "post") {
          config.headers.set("X-CSRFTOKEN", csrfToken);
        }

        return config;
      },
      (error) => {
        console.error("Request interceptor error:", error);
        return Promise.reject(error);
      }
    );

    return () => {
      instance.interceptors.request.eject(requestInterceptor);
    };
  }, [cookies]);

  return <AppRouter />;
}

export default App;
