import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

import "./DecoratedLink.css";

export interface Props {
  title: string;
  slug: string;
  menu_image: string;
  menu_image_top: number;
  menu_image_right: number;
  redirect_link: string | null;
}

export const DecoratedLink = ({
  title,
  slug,
  redirect_link,
  menu_image,
  menu_image_top,
  menu_image_right,
}: Props) => {
  const [redirectTo, setRedirectTo] = useState<string>("");

  useEffect(() => {
    redirect_link ? setRedirectTo(redirect_link) : setRedirectTo(slug || "");
  }, [redirect_link, slug]);

  const linkProps = redirect_link
    ? { target: "_blank", rel: "noopener noreferrer" }
    : {};

  return (
    <li className="decorated-link">
      <Link className="decorated-link__inner" to={redirectTo} {...linkProps}>
        <div
          className="decorated-link__sticker"
          style={{
            top: menu_image_top + "rem",
            right: menu_image_right + "rem",
          }}
        >
          <img src={menu_image} alt={title} />
        </div>

        <div className="decorated-link__text">{title}</div>
      </Link>
    </li>
  );
};
