import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { fetchPaymentHistoryByFeederId } from "../actions/actions";
import { PaymentHistoryItemI } from "../../types/PaymentHistoryItem.types";
import { PaymentHistoryResponseI } from "../../types/PaymentHistoryResponse.types";
import { DEFAULT_ERROR_MESSAGE } from "../../constants/validationConstants";
interface PaymentHistoryStateI {
  isLoading: boolean;
  error: string | null;
  paymentHistory: PaymentHistoryItemI[];
  count: number;
  isFetchingData: boolean;
  pageSize: number;
}

export const initialState: PaymentHistoryStateI = {
  count: 0,
  isLoading: false,
  error: null,
  paymentHistory: [],
  isFetchingData: true,
  pageSize: 5,
};

export const paymentHistorySlice = createSlice({
  name: "paymentHistory",
  initialState,
  reducers: {
    setPaymentHistories: (state, action) => {
      state.paymentHistory = state.paymentHistory.concat(action.payload);
    },
    setIsFetchingData: (state, action) => {
      state.isFetchingData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPaymentHistoryByFeederId.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        fetchPaymentHistoryByFeederId.fulfilled,
        (state, action: PayloadAction<PaymentHistoryResponseI>) => {
          state.isLoading = false;
          state.error = null;
          // state.paymentHistory = [...state.paymentHistory, ...action.payload.results];
          const newItems = action.payload.results.filter(
            (newItem) =>
              !state.paymentHistory.some(
                (existingItem) => existingItem.id === newItem.id
              )
          );
          state.paymentHistory = [...state.paymentHistory, ...newItems];
          state.count = action.payload.count;
          state.count = action.payload.count;
        }
      )
      .addCase(
        fetchPaymentHistoryByFeederId.rejected,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.error = action.payload
            ? action.payload.message
            : DEFAULT_ERROR_MESSAGE;
        }
      );
  },
});

export const { setPaymentHistories, setIsFetchingData } =
  paymentHistorySlice.actions;

export default paymentHistorySlice.reducer;
