import { combineReducers, configureStore } from "@reduxjs/toolkit";
import pagesReducer from "./reducers/pagesSlice";
import errorsReducer from "./reducers/errorsSlice";
import defaultLayoutReducer from "./reducers/defaultLayoutPageSlice";
import feedersReducer from "./reducers/feedersSlice";
import currentFeederReducer from "./reducers/currentFeederSlice";
import paymentReducer from "./reducers/paymentSlice";
import paymentHistoryReducer from "./reducers/paymentHistorySlice";
import paymentCommentReducer from "./reducers/paymentCommentSlice";
import userReducer from "./reducers/userSlice";
import blogPostsReducer from "./reducers/blogPostsSlice";
import notificationReducer from "./reducers/notificationSlice";

const isDevelopment = process.env.NODE_ENV === 'development';

const rootReducer = combineReducers({
  pagesReducer,
  errorsReducer,
  defaultLayoutReducer,
  feedersReducer,
  currentFeederReducer,
  paymentReducer,
  paymentHistoryReducer,
  paymentCommentReducer,
  userReducer,
  blogPostsReducer,
  notificationReducer
});

export const store = configureStore({
  reducer: rootReducer,
  devTools: isDevelopment
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;
