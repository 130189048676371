import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { fetchPageById } from "../actions/actions";
import { PageByIdResponseI } from "../../types/PageByIdResponse.types";
import { DEFAULT_ERROR_MESSAGE } from "../../constants/validationConstants";

interface DefaultLayoutPageState {
  pageData: null | PageByIdResponseI;
  isLoading: boolean;
  error: null | string;
}

export const initialState: DefaultLayoutPageState = {
  pageData: null,
  isLoading: false,
  error: null,
};

export const defaultLayoutPageSlice = createSlice({
  name: "defaultPage",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPageById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchPageById.fulfilled, (state, action: PayloadAction<PageByIdResponseI>) => {
        state.isLoading = false;
        state.error = null;
        state.pageData = action.payload;
      })
      .addCase(fetchPageById.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload ? action.payload.message: DEFAULT_ERROR_MESSAGE;
      });
  },
});

export default defaultLayoutPageSlice.reducer;