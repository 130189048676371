import { PayloadAction, createSlice, isRejected } from "@reduxjs/toolkit";
import { DEFAULT_ERROR_MESSAGE } from "../../constants/validationConstants";

export const errorsSlice = createSlice({
  name: "errors",
  initialState: {
    message: "",
  },
  reducers: {
    hideError(state) {
      state.message = "";
    },
    setErrorMessage(state, action: PayloadAction<string>) {
      state.message = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(isRejected, (state, action) => {
      state.message =
        typeof action.payload === "string"
          ? action.payload
          : DEFAULT_ERROR_MESSAGE;
    });
  },
});

export const { hideError, setErrorMessage } = errorsSlice.actions;

export default errorsSlice.reducer;
