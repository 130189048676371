import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { FeederI } from "../../types/Feeder.types";
import { fetchFeederById } from "../actions/actions";
import { DEFAULT_ERROR_MESSAGE } from "../../constants/validationConstants";

interface CurrentFeederStateI {
  feeder: null | FeederI;
  isLoading: boolean;
  error: null | string;
}

export const initialState: CurrentFeederStateI = {
  feeder: null,
  isLoading: false,
  error: null,
};

export const currentFeederSlice = createSlice({
  name: "currentFeeder",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFeederById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        fetchFeederById.fulfilled,
        (state, action: PayloadAction<FeederI>) => {
          state.isLoading = false;
          state.error = null;
          state.feeder = action.payload;
        }
      )
      .addCase(fetchFeederById.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload ? action.payload.message: DEFAULT_ERROR_MESSAGE;
      });
  },
});

export default currentFeederSlice.reducer;