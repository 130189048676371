import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  fetchNotificationStatusByFeederId,
  updateNotification,
} from "../actions/actions";
import { DEFAULT_ERROR_MESSAGE } from "../../constants/validationConstants";
import { NotificationStatusResponseI } from "../../types/NotificationStatusResponse.types";
import { UpdateNotificationStatusResponseI } from "../../types/UpdateNotificationStatusResponse.types";

interface NotificationStateI {
  notificationStatus: boolean;
  isLoading: boolean;
  error: null | string;
}

export const initialState: NotificationStateI = {
  notificationStatus: false,
  isLoading: false,
  error: null,
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotificationStatusByFeederId.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        fetchNotificationStatusByFeederId.fulfilled,
        (state, action: PayloadAction<NotificationStatusResponseI>) => {
          state.isLoading = false;
          state.error = null;
          state.notificationStatus = action.payload.notification;
        }
      )
      .addCase(
        fetchNotificationStatusByFeederId.rejected,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.error = action.payload
            ? action.payload.message
            : DEFAULT_ERROR_MESSAGE;
        }
      )
      .addCase(updateNotification.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        updateNotification.fulfilled,
        (state, action: PayloadAction<UpdateNotificationStatusResponseI>) => {
          state.isLoading = false;
          state.error = null;
          state.notificationStatus = action.payload.notification;
        }
      )
      .addCase(
        updateNotification.rejected,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.error = action.payload
            ? action.payload.message
            : DEFAULT_ERROR_MESSAGE;
        }
      );
  },
});

export default notificationSlice.reducer;
