import { DecoratedLink } from "../../components/decorated-link/DecoratedLink";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

import { PageLinkI } from "../../types/PageLink.types";
import { Spinner } from "../../components/spinner/Spinnert";
import "./HomePage.css";

const HomePage = () => {
  const pages: PageLinkI[] = useSelector(
    (state: RootState) => state.pagesReducer.pages
  );
  const isLoadingPages = useSelector(
    (state: RootState) => state.pagesReducer.isLoading
  );

  return (
    <>
      {pages && (
        <nav className="nav">
          <ul>
            {pages &&
              pages.map(
                (page: PageLinkI) =>
                  page.index_link && (
                    <DecoratedLink
                      key={page.id}
                      title={page.menu_title}
                      slug={page.slug}
                      redirect_link={page.redirect_link}
                      menu_image={page.menu_image}
                      menu_image_top={page.menu_image_top}
                      menu_image_right={page.menu_image_right}
                    />
                  )
              )}
          </ul>
        </nav>
      )}
      
      {isLoadingPages && <Spinner/>}

    </>
  );
};

export default HomePage;
