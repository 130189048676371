import axios, { AxiosResponse } from "axios";
import { HTTP_СONSTANTS } from "../constants/apiConstants";
import { PaymentData } from "../types/PaymentData.types";

import { PaymentHistoryRequestParamsI } from "../types/PaymentHistoryRequestParams.types";
import { PaymentCommentRequestI } from "../types/PaymentCommentRequest.types";
import { PageLinkI } from "../types/PageLink.types";
import { PageByIdResponseI } from "../types/PageByIdResponse.types";
import { FeederI } from "../types/Feeder.types";
import { FeedersResponseI } from "../types/FeedersResponse.types";
import { PaymentHistoryResponseI } from "../types/PaymentHistoryResponse.types";
import { PaymentHistoryItemI } from "../types/PaymentHistoryItem.types";
import { FeederBlogRequestParamsI } from "../types/FeederBlogRequestParams.types";
import { NotificationStatusResponseI } from "../types/NotificationStatusResponse.types";
import { NotificationStatusRequestParamsI } from "../types/NotificationStatusRequestParams.types";
import { UpdateNotificationStatusRequestI } from "../types/UpdateNotificationStatusRequest.types";
import { UpdateNotificationStatusResponseI } from "../types/UpdateNotificationStatusResponse.types";
import { BlogPostsByFeederIdResponseI } from "../types/BlogPostsByFeederIdResponse.types";

export const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: { "Content-Type": "application/json" },
});

export const pagesApi = {
  getPages: (): Promise<AxiosResponse<PageLinkI[]>> => {
    return instance.get<PageLinkI[]>(HTTP_СONSTANTS.API_PAGES);
  },
  getPageById: (id: number): Promise<AxiosResponse<PageByIdResponseI>> => {
    return instance.get<PageByIdResponseI>(HTTP_СONSTANTS.API_PAGES_BY_ID(id));
  },
};

export const feedersApi = {
  getFeeders: (): Promise<AxiosResponse<FeedersResponseI>> => {
    return instance.get<FeedersResponseI>(HTTP_СONSTANTS.API_FEEDERS);
  },
  getFeederById: (id: number): Promise<AxiosResponse<FeederI>> => {
    return instance.get<FeederI>(HTTP_СONSTANTS.API_FEEDER_BY_ID(id));
  },
};

export const paymentApi = {
  generateDonationLink: ({
    token,
    feederId,
    formData,
  }: PaymentData): Promise<AxiosResponse<string>> => {
    return instance.post<string>(
      HTTP_СONSTANTS.API_GENERATE_PAYMENT_LINK(token, feederId),
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  },
  getPaymentHistoryByFeederId: (
    fetchingParams: PaymentHistoryRequestParamsI
  ): Promise<AxiosResponse<PaymentHistoryResponseI>> => {
    return instance.get<PaymentHistoryResponseI>(
      HTTP_СONSTANTS.API_PAYMENT_HISTORY(
        fetchingParams.feederId,
        fetchingParams.pageSize,
        fetchingParams.currentPage
      )
    );
  },
  createPaymentComment: ({
    token,
    formData,
  }: PaymentCommentRequestI): Promise<AxiosResponse<PaymentHistoryItemI>> => {
    return instance.post<any>(
      HTTP_СONSTANTS.API_PAYMENT_COMMENT(token),
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  },
};

export const blogApi = {
  getBlogPostsByFeederId: (
    fetchingParams: FeederBlogRequestParamsI
  ): Promise<AxiosResponse<BlogPostsByFeederIdResponseI>> => {
    return instance.get<BlogPostsByFeederIdResponseI>(
      HTTP_СONSTANTS.API_FEEDER_BLOG(
        fetchingParams.feederId,
        fetchingParams.pageSize,
        fetchingParams.currentPage
      )
    );
  },
};

export const notificationApi = {
  getNotificationStatus: (
    fetchingParams: NotificationStatusRequestParamsI
  ): Promise<AxiosResponse<NotificationStatusResponseI>> => {
    return instance.get<NotificationStatusResponseI>(
      HTTP_СONSTANTS.API_NOTIFICATION_STATUS(
        fetchingParams.feederId,
        fetchingParams.token
      )
    );
  },
  updateNotificationStatus: (
    data: UpdateNotificationStatusRequestI
  ): Promise<AxiosResponse<UpdateNotificationStatusResponseI>> => {
    return instance.post<UpdateNotificationStatusResponseI>(
      HTTP_СONSTANTS.API_NOTIFICATION_UPDATE,
      data
    );
  },
};
