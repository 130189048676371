export const COMMENT_MAX_LENGTH: number = 250;
export const COMMENT_AUTHOR_MAX_LENGTH: number = 24;
export const ELLIPSIS: string = '...';

export const EMAIL_REGEX: RegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const ERROR_MESSAGES = {
  invalidEmailFormat: "Проверьте правильность введенного Email",
  isNotAgreedToPolicy: "Необходимо согласие с политикой конфиденциальности",
  commentMaxLength: `He более ${COMMENT_MAX_LENGTH} знаков в комментарии`
};

export const DEFAULT_ERROR_MESSAGE: string = "Ой, что-то пошло не так...";
