import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface UserStateI {
  token: string | null;
  username: string | null;
}

export const initialState: UserStateI = {
  token: null,
  username: null
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    setUserName: (state, action: PayloadAction<string>) => {
      state.username = action.payload;
    }
  },
});

export const { setUserToken, setUserName } = userSlice.actions;

export default userSlice.reducer;
